<template>
  <v-chip
    :close="close"
    :color="statusColor + ' lighten-5'"
    :class="statusColor + '--text text--darken-4'"
    @click:close="emitClickClose"
  >
    <v-avatar v-if="marker" left class="mr-3">
      <v-img :src="'/media/marker/Marker_' + statusColor + '.png'"></v-img>
    </v-avatar>
    {{ statusName }}
  </v-chip>
</template>

<script>
import status_i18n from "../config/i18n/status";

export default {
  name: "StatusChip",
  props: {
    statusGroup: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    close: {
      type: Boolean,
      default: false,
      required: false,
    },
    marker: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    statusName() {
      return status_i18n.STATUS_GROUP[this.statusGroup].values[this.status]
        .label;
    },
    statusColor() {
      return status_i18n.STATUS_GROUP[this.statusGroup].values[this.status]
        .color;
    },
  },
  methods: {
    emitClickClose() {
      this.$emit("click:close");
    },
  },
};
</script>

<style scoped></style>
