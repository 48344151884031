import * as helpers from "@turf/helpers";
import BBox from "@turf/bbox";
import BBoxPolygon from "@turf/bbox-polygon";
import area from "@turf/area";

class MapHelperService {
  constructor(map) {
    this.map = map;
  }

  getMapBBoxPolygon() {
    if (this.map.getBounds()) {
      const viewport = [
        [
          this.map.getBounds().getNorthEast().lat(),
          this.map.getBounds().getNorthEast().lng(),
        ],
        [
          this.map.getBounds().getSouthWest().lat(),
          this.map.getBounds().getSouthWest().lng(),
        ],
      ];

      const lineString = helpers.lineString(viewport);
      const bbox = BBox(lineString);
      return BBoxPolygon(bbox);
    } else {
      throw new Error("Bounds not defined");
    }
  }

  getMapBBoxCoordinates() {
    return this.getMapBBoxPolygon().geometry.coordinates[0].map((arr) => ({
      lat: arr[0],
      lng: arr[1],
    }));
  }

  getMapBBoxArea() {
    return area(this.getMapBBoxPolygon()) / 1000000;
  }

  centerLocation(location) {
    this.map.setCenter(location);
  }

  setZoom(zoom) {
    this.map.setZoom(zoom);
  }

  centerGeometry(geometry) {
    this.map.setCenter(geometry.location);
    this.map.fitBounds(geometry.viewport);
  }

  fitBoundsFromPolygon(polygon) {
    let bounds = new window.google.maps.LatLngBounds();

    const latLng = [
      {
        lat: polygon.geometry.coordinates[0][0][0],
        lng: polygon.geometry.coordinates[0][0][1],
      },
      {
        lat: polygon.geometry.coordinates[0][2][0],
        lng: polygon.geometry.coordinates[0][2][1],
      },
    ];

    bounds.extend(latLng[0]); // nw
    bounds.extend(latLng[1]); // se

    this.fitBounds(bounds);
  }

  fitBounds(bounds, padding = 80) {
    this.map.fitBounds(bounds, padding);
  }
}

export default MapHelperService;
