const locale = {
  STATUS_GROUP: {
    homesPassed: {
      label: "Trassenstatus",
      values: {
        not_build: {
          label: "Nicht verlegt",
          color: "blue",
        },
        in_planning: {
          label: "In Planung",
          color: "purple",
        },
        in_construction: {
          label: "In Bau",
          color: "orange",
        },
        finished: {
          label: "Verlegt",
          color: "green",
        },
      },
    },
    homesContracted: {
      label: "Vertragsstatus",
      values: {
        not_offered: {
          label: "Kein Vertrag",
          color: "blue",
        },
        offered: {
          label: "Angeboten",
          color: "orange",
        },
        rejected: {
          label: "Abgelehnt/Widerrufen",
          color: "red",
        },
        signed: {
          label: "Vertrag unterschrieben",
          color: "green",
        },
      },
    },
    homesConnected: {
      label: "Hausanschluss Status",
      values: {
        open: {
          label: "Noch nicht gebaut",
          color: "red",
        },
        done: {
          label: "Angeschlossen",
          color: "green",
        },
      },
    },
    homesActivated: {
      label: "Inbetriebnahme Status",
      values: {
        inactive: {
          label: "Inaktiv",
          color: "red",
        },
        active: {
          label: "Aktiv",
          color: "green",
        },
      },
    },
    phaseDgn: {
      label: "DGN Phase",
      values: {
        phase_1: {
          label: "1. Eigenwirtschaftlicher Ausbau",
          color: "blue",
        },
        phase_2: {
          label: "2. Kritische Ausbaukosten/zurückgestellt",
          color: "green",
        },
        phase_3: {
          label: "3. Gewerbegebiet",
          color: "orange",
        },
        phase_4: {
          label: "4. Geförderter Ausbau geplant/realisiert",
          color: "red",
        },
        phase_5: {
          label: "5. Ausbau durch andere TKU realisiert/geplant",
          color: "purple",
        },
      },
    },
  },
};

export default locale;
