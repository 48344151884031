<template>
  <div>
    <v-row v-if="circles.length === 0" no-gutters>
      <v-col>
        <v-btn
          v-if="drawingNewCircle"
          @click="abortDrawingNewCircle"
          block
          color="error"
        >
          <v-icon class="mr-2">mdi-close</v-icon>
          Zeichnen Abbrechen
        </v-btn>
        <v-btn v-else-if="circles.length === 0" block @click="drawNewCircle">
          <v-icon class="mr-2">mdi-draw</v-icon>
          Kreis zeichnen
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-show="drawingNewCircle">
      <v-col>
        <v-alert text icon="mdi-information-outline">
          Bitte klicken Sie in die Karte um einen neuen Kreis zu zeichnen.
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="circles[0]" no-gutters>
      <v-col>
        <v-btn block class="mb-5" @click.prevent="removeCircle(circles[0])">
          <v-icon class="mr-2">mdi-close</v-icon>
          Auswahl entfernen
        </v-btn>

        <span class="title">Informationen zur aktuellen Auswahl</span><br />
        <span>{{ polygonArea }} km² Fläche</span><br />
        <span>{{ Math.round(circles[0].circle.radius) }} m Radius</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ApiService from "../../../../services/api.service";

import { mapGetters } from "vuex";

export default {
  name: "ToolSettingsPerimeter",
  data() {
    return {
      centerAddress: null,
      radius: 25,
      activeCircleIndex: null,
      loadingNewCircle: false,
      drawingNewCircle: false,
    };
  },
  computed: {
    activeCircle() {
      return this.circles[this.activeCircleIndex];
    },
    polygonArea() {
      const area = Math.PI * Math.pow(this.circles[0].circle.radius, 2);

      return Math.round(area / 10000) / 100;
    },
    addressIds() {
      let addressIds = [];

      for (let i = 0, l = this.circles.length; i < l; i++) {
        const addresses = this.circles[i].addresses;

        for (const address of addresses) {
          if (!addressIds.includes(address.address.id)) {
            addressIds.push(address.address.id);
          }
        }
      }

      return addressIds;
    },
    ...mapGetters(["circles"]),
  },
  methods: {
    generateCircle() {
      this.$root.$emit("drawNewCircle", {
        centerAddress: this.centerAddress,
        radius: this.radius,
      });
    },

    abortDrawingNewCircle() {
      this.drawingNewCircle = false;
      this.$root.$emit("abortDrawingNewCircle");
    },

    drawNewCircle() {
      this.drawingNewCircle = true;
      this.$root.$emit("drawNewCircle");
    },

    async addCircle(circle) {
      this.loadingNewCircle = true;

      let count = 0;
      let addresses = [];
      const centerAddress = this.centerAddress;

      await ApiService.post("api/sales-control/by-radius", {
        lat: circle.center.lat(),
        lng: circle.center.lng(),
        r: circle.radius / 1000,
      }).then(function (response) {
        count = response.data.count;
        addresses = response.data.items;
      });

      this.circles.push({ count, circle, addresses, centerAddress });
      this.loadingNewCircle = false;
    },

    removeAllCircles() {
      for (let i = 0, l = this.circles.length; i < l; i++) {
        this.circles[i].polygon.setMap(null);
        this.$store.dispatch("deleteCircle", this.circles[i]);
      }

      this.$store.dispatch("setMapToolFilter", null);
    },

    removeCircle(circle) {
      circle.circle.setMap(null);
      this.$store.dispatch("deleteCircle", circle);
    },

    viewCircle() {
      for (let i = 0, l = this.circles.length; i < l; i++) {
        const circle = this.circles[i].circle;

        circle.setOptions({
          fillColor: "#000000",
          strokeColor: "#000000",
          editable: false,
        });
      }

      const activeCircle = this.activeCircle;

      if (activeCircle) {
        activeCircle.circle.setOptions({
          fillColor: "#0000ff",
          strokeColor: "#0000ff",
          editable: false,
        });
      }
    },
  },
  watch: {
    addressIds() {
      if (this.circles.length === 0) {
        this.$store.dispatch("setMapToolFilter", null);
      } else {
        this.$store.dispatch("setMapToolFilter", this.addressIds);
      }

      this.$emit("change", this.circles.length > 0);
    },
    circles() {
      this.abortDrawingNewCircle();
    },
  },
  created() {
    this.$root.$on("addedCircle", this.addCircle);
  },
  beforeDestroy() {
    this.removeAllCircles();
  },
};
</script>

<style scoped></style>
