<template>
  <div style="width: 550px">
    <span class="title">
      {{ cluster.label }}
      <span class="text--secondary">({{ clusterResolution }})</span>
    </span>
    <br />
    <span class="subtitle-1">{{ cluster.nAddresses }} Adressen</span>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr v-for="(statusGroup, key) in clusterStatus" :key="key">
            <td>{{ getStatusLabel(key) }}</td>
            <td v-if="key === 'homesContracted'">
              <div
                v-for="(status, statusKey) in statusGroup"
                :key="statusKey"
                class="py-1"
              >
                <b>{{ cluster[statusKeyTranslation[key][statusKey]] }} Einh.</b>
                ({{
                  getStatusUnitCount(key) > 0
                    ? Math.round(
                        (cluster[statusKeyTranslation[key][statusKey]] /
                          getStatusUnitCount(key)) *
                          10000
                      ) / 100
                    : 0
                }}%)
                <status-chip
                  class="ml-3"
                  :status="statusKey"
                  :status-group="key"
                />
              </div>
            </td>
            <td v-else>
              <div
                v-for="(status, statusKey) in statusGroup"
                :key="statusKey"
                class="py-1"
              >
                <b>{{ status.n }} Adr.</b>
                ({{
                  Math.round((status.n / cluster.nAddresses) * 10000) / 100
                }}%)
                <status-chip
                  class="ml-3"
                  :status="statusKey"
                  :status-group="key"
                />
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import StatusChip from "@/components/StatusChip";
import status_i18n from "@/config/i18n/status";

export default {
  name: "ClusterMarkerInfoWindow",
  components: { StatusChip },
  props: {
    cluster: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusKeyTranslation: {
        homesContracted: {
          not_offered: "nhc0",
          signed: "nhc1",
        },
      },
    };
  },
  computed: {
    clusterResolution() {
      switch (this.cluster["@type"]) {
        case "county":
          return "Landkreis";
        case "city":
          return "Stadt";
        case "cityDistrict":
          return "Stadtteil";
        case "street":
          return "Straße";
        default:
          return this.cluster["@type"];
      }
    },
    clusterStatus() {
      let statusArray = {};

      for (let i = 0; i < this.cluster.items.length; i++) {
        const currStatus = this.cluster.items[i];

        if (!statusArray[currStatus.statusKey]) {
          statusArray[currStatus.statusKey] = {};
        }

        statusArray[currStatus.statusKey][currStatus.statusValue.key] = {
          n: currStatus.n,
          nhu: currStatus.nhu,
          nbu: currStatus.nbu,
        };
      }

      return statusArray;
    },
  },
  methods: {
    getStatusLabel(statusGroup) {
      const statusLabel = status_i18n.STATUS_GROUP[statusGroup]
        ? status_i18n.STATUS_GROUP[statusGroup].label
        : statusGroup;
      return this.label ?? statusLabel;
    },
    getStatusUnitCount(statusKey) {
      if (statusKey === "homesContracted") {
        let count = 0;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(
          this.statusKeyTranslation[statusKey]
        )) {
          count += this.cluster[value];
        }
        return count;
      } else {
        const statusItems = this.cluster.items.filter(
          (obj) => obj.statusKey === statusKey
        );
        let unitsCount = 0;

        statusItems.map((status) => {
          unitsCount += status.nbu + status.nhu;
        });

        return unitsCount;
      }
    },
  },
};
</script>

<style scoped></style>
