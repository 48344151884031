<template>
  <div v-if="hasNoGroups">
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="auto">
          Adressdaten
          <v-btn class="ml-5" @click="updateAddresses">
            <v-icon class="mr-2">mdi-autorenew</v-icon>
            Aktualisieren
          </v-btn>
          <v-btn
            v-if="addresses.length > 0"
            class="ml-5 mr-5"
            @click="exportToExcel"
          >
            <v-icon class="mr-2">mdi-file-excel</v-icon>
            Download Tabelle
            <span v-show="selectedAddresses.length > 0"> (Nur Auswahl) </span>
          </v-btn>
          <address-bulk-edit-modal
            v-if="selectedAddresses.length > 0"
            :addresses="selectedAddresses"
            @close="selectedAddresses = []"
          />
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-text-field
            v-model="searchValue"
            append-icon="mdi-magnify"
            label="Suchen"
            clearable
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      v-model="selectedAddresses"
      :loading="loadingData"
      :headers="headers"
      :items="addresses"
      :options.sync="options"
      loading-text="Keine Adressen gefunden"
      hide-default-footer
      :page.sync="tableControls.pagination.currentPage"
      :items-per-page="tableControls.pagination.itemsPerPage"
      multi-sort
      @page-count="tableControls.pagination.pageCount = $event"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn icon text @click="centerLocation(item.location)">
          <v-icon>mdi-crosshairs-gps</v-icon>
        </v-btn>
      </template>

      <template v-slot:footer>
        <data-table-footer-controls
          :item-count="totalAddresses"
          v-model="tableControls"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { json2excel } from "js2excel";
import i18nDiclinaService from "../../../services/diclina_i18n";
import DataTableFooterControls from "../../../components/DataTableFooterControls";
import AddressBulkEditModal from "../../../components/AddressBulkEditModal";
import { CHECK_GROUPS } from "@/store/auth.module";
import { mapGetters } from "vuex";

export default {
  name: "AddressDataTable",
  components: {
    DataTableFooterControls,
    AddressBulkEditModal,
  },
  props: ["headers", "itemKey", "loading"],
  data() {
    return {
      addresses: [],
      loadingData: false,
      totalAddresses: 0,
      options: {},
      searchValue: "",
      awaitingSearch: false,
      searchTimeout: null,
      selectedAddresses: [],
      tableControls: {
        pagination: {
          itemsPerPage: 15,
          currentPage: 1,
          pageCount: 0,
          itemsPerPageOptions: [10, 15, 25, 50, 100],
        },
      },
    };
  },

  computed: {
    ...mapGetters(["userGroups"]),
    statusGroups() {
      return this.$store.getters.statusGroups;
    },
    hasNoGroups() {
      return this.userGroups.data?.length === 0;
    },
  },
  mounted() {
    this.$store.dispatch(CHECK_GROUPS);
  },
  methods: {
    async updateAddresses() {
      this.loadingData = true;

      const payload = {
        q: this.searchValue ?? "",
        size: this.tableControls.pagination.itemsPerPage,
        page: this.tableControls.pagination.currentPage,
        sort: this.options.sortBy
          ? this.options.sortBy.map((x) => x.substring(x.lastIndexOf(".") + 1))
          : null,
        dir: this.options.sortDesc
          ? this.options.sortDesc.map((x) => (x ? 0 : 1))
          : null,
      };

      const webFilterArray = this.$store.getters.webFilterArray();

      for (let i = 0; i < webFilterArray.length; i++) {
        payload[webFilterArray[i].key] = webFilterArray[i].value;
      }

      let result = await this.$store
        .dispatch("getAddresses", payload)
        .catch((e) => {
          this.addresses = [];
          this.loadingData = false;
          throw new Error(e);
        });

      if (result.meta.maxPages < this.tableControls.pagination.currentPage) {
        this.tableControls.pagination.currentPage = result.meta.maxPages;
        await this.updateAddresses();
      } else {
        this.addresses = result.data;
        this.totalAddresses = result.meta.max;
        this.tableControls.pagination.pageCount = result.meta.maxPages;
        this.loadingData = false;
      }
    },

    exportToExcel() {
      let data = this.exportArray();

      json2excel({
        data,
        name: "Adressdaten",
        formatDate: "dd.mm.yyyy",
      });
    },

    centerLocation(location) {
      this.$emit("center-location", location);
    },

    exportArray() {
      let exportArray = [];

      let itemArray = this.addresses;

      for (let i = 0; i < itemArray.length; i++) {
        let currRow = itemArray[i];
        exportArray[i] = [];

        for (let j = 0; j < this.headers.length; j++) {
          let currHeader = this.headers[j];

          if (
            !currHeader.value.startsWith("status.") &&
            currHeader.value !== "address.personId"
          ) {
            exportArray[i][currHeader.text] = Object.byString(
              currRow,
              currHeader.value
            );
          } else if (currHeader.value === "address.personId") {
            exportArray[i][currHeader.text] = this.getSalesPersonName(
              currRow.address.personId
            );
          }
        }
      }

      return exportArray;
    },

    getStatusName(statusGroup, status) {
      return i18nDiclinaService.getDiclinaStatusName(statusGroup, status);
    },

    getStatusGroupSlotName(statusGroup) {
      return "item.status." + statusGroup;
    },

    getStatusColor(statusGroup, status) {
      return i18nDiclinaService.getDiclinaStatusStyle(statusGroup, status)
        .color;
    },

    getSalesPerson(salesPersonId) {
      return this.$store.getters.salesPersonById(salesPersonId);
    },

    getSalesPersonName(salesPersonId) {
      const salesPerson = this.getSalesPerson(salesPersonId);

      if (!salesPerson) {
        return "Unbekannt";
      } else {
        return salesPerson.firstName + " " + salesPerson.lastName;
      }
    },

    saveStatusGroup(item, statusGroup) {
      let updatedAddress = {
        address: {
          id: item.address.id,
        },
        status: {},
      };
      updatedAddress.status[statusGroup] = item.status[statusGroup];

      this.$store.dispatch("bulkUpdateAddresses", [updatedAddress]);
      this.$refs[
        this.getEditDialogRef(statusGroup, item.address.id)
      ][0].cancel();
    },

    saveSalesPerson(item) {
      if (item.address.personId || item.address.personId === 0) {
        let updatedAddress = {
          address: {
            id: item.address.id,
            personId: item.address.personId,
          },
        };

        this.$store.dispatch("bulkUpdateAddresses", [updatedAddress]);
        this.$refs["edit-dialog-sales-person-" + item.address.id].cancel();
      }
    },

    getEditDialogRef(statusGroup, addressId) {
      return "edit-dialog-" + statusGroup + "-" + addressId;
    },

    selectAll(params) {
      this.selectedAddresses = params.value ? this.addresses : [];
    },

    getAmountHouseHolds(location) {
      return location.info.amountHouseHoldsPriv + location.info.amountCompanies;
    },

    getCustomerCountByProductStatus(location, status) {
      const customers = location.customers || [];

      if (status === "open") {
        return this.getAmountHouseHolds(location) - customers.length;
      } else {
        return customers.filter((item) => {
          return item.status.product === status;
        }).length;
      }
    },
  },

  created() {
    this.updateAddresses();
  },

  watch: {
    searchValue() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.awaitingSearch = false;
        this.updateAddresses();
      }, 500); // 0.5 sec delay
    },

    selectedAddresses() {
      this.$emit("changedSelectedAddresses", this.selectedAddresses);
    },

    options: {
      handler() {
        this.updateAddresses();
      },
      deep: true,
    },

    tableControls: {
      handler() {
        this.options.page = this.tableControls.pagination.currentPage;
        this.options.itemsPerPage = this.tableControls.pagination.itemsPerPage;
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
