import status_i18n from "@/config/i18n/status";
import ColorService from "@/services/colors/color.service";
import FilterHelperService from "@/services/filter-helper.service";

class ClusterMarkerSingleAddressHomesContractedService {
  static getCount(address) {
    return getHomesContractedSum(address);
  }

  static getSections(address) {
    let sections = [];

    const homesContractedSum = getHomesContractedSum(address);

    for (const [key, value] of Object.entries(
      status_i18n.STATUS_GROUP.homesContracted.values
    )) {
      if (getFilterStatus().includes(key)) {
        const percent =
          (address.stats[statusKeyTranslation[key]] / homesContractedSum) * 100;

        sections.push({
          label:
            value.label +
            ": " +
            address.stats[statusKeyTranslation[key]] +
            " (" +
            Math.round(percent * 100) / 100 +
            "%)",
          value: percent,
          color: ColorService.getTransparentColorByName(
            status_i18n.STATUS_GROUP.homesContracted.values[key].color,
            0.6
          ),
        });
      }
    }

    return sections;
  }
}

const statusKeyTranslation = {
  not_offered: "amountContractNotOffered",
  offered: "amountContractOffered",
  rejected: "amountContractRejected",
  signed: "amountContractSigned",
};

const getHomesContractedSum = (address) => {
  let result = 0;

  const filterStatus = getFilterStatus();
  for (let i = 0; i < filterStatus.length; i++) {
    result += address.stats[statusKeyTranslation[filterStatus[i]]];
  }

  return result;
};

const getFilterStatus = () => {
  const filterHelperService = new FilterHelperService();
  return filterHelperService.getFilter()[
    filterHelperService.getSingleSelectedStatusKey()
  ];
};
//
// const getLabel = (status, count, percent) => {
//   const filterHelperService = new FilterHelperService();
//   return (
//     status_i18n.STATUS_GROUP[filterHelperService.getSingleSelectedStatusKey()]
//       .values[status].label +
//     ": " +
//     count +
//     " (" +
//     Math.round(percent * 100) / 100 +
//     "%)"
//   );
// };

export default ClusterMarkerSingleAddressHomesContractedService;
