import * as helpers from "@turf/helpers";
import area from "@turf/area";
import circle from "@turf/circle";
import intersect from "@turf/intersect";

class TurfHelperService {
  createPolygon(coordinates) {
    if (coordinates[0] !== coordinates[coordinates.length - 1]) {
      coordinates.push(coordinates[0]);
    }

    return helpers.polygon([coordinates]);
  }

  createCircle(coordinates, radius) {
    return circle(coordinates, radius);
  }

  intersectCoordinates(coordinates1, coordinates2) {
    return intersect(
      this.createPolygon(coordinates1),
      this.createPolygon(coordinates2)
    );
  }

  getPolygonArea(coordinates) {
    return area(this.createPolygon(coordinates));
  }

  getCircleArea(coordinates) {
    return area(this.createPolygon(coordinates));
  }
}

export default TurfHelperService;
