<template>
  <div>
    <v-autocomplete
      v-model="content"
      :items="items"
      :multiple="multiple"
      :label="itemLabel"
      item-text="label"
      clearable
      v-on="$listeners"
      @change="handleInput"
    >
      <!--      <template v-slot:selection="data">-->
      <!--        <status-chip-->
      <!--          :status="data.item"-->
      <!--          :status-group="statusGroup.values.key"-->
      <!--          :close="multiple"-->
      <!--          @click:close="remove(data.item)"-->
      <!--        />-->
      <!--      </template>-->
      <!--      <template v-slot:item="data">-->
      <!--        <v-list-item-avatar size="sm" class="mr-3">-->
      <!--          <v-img-->
      <!--            :src="-->
      <!--              '/media/marker/Marker_' +-->
      <!--              getStatusColor(statusGroup.value.key, data.item) +-->
      <!--              '.png'-->
      <!--            "-->
      <!--          />-->
      <!--        </v-list-item-avatar>-->
      <!--        <v-list-item-content-->
      <!--          v-text="getStatusName(statusGroup.value.key, data.item)"-->
      <!--        />-->
      <!--      </template>-->
    </v-autocomplete>
  </div>
</template>

<script>
import i18nDiclinaService from "../services/diclina_i18n";
import StatusChip from "./StatusChip";
import status_i18n from "../config/i18n/status";

export default {
  name: "StatusAutoComplete",
  // eslint-disable-next-line vue/no-unused-components
  components: { StatusChip },

  props: {
    value: [Array, String],
    statusGroup: Object,
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      content: this.value,
    };
  },
  computed: {
    itemLabel() {
      return (
        this.label ?? status_i18n.STATUS_GROUP[this.statusGroup.name].label
      );
    },

    items() {
      return this.statusGroup.values.map((value) => {
        value.label =
          status_i18n.STATUS_GROUP[this.statusGroup.name].values[
            value.key
          ].label;
      });
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    getStatusName(status) {
      return status_i18n.STATUS_GROUP[this.statusGroup.name].values[status]
        .label;
    },

    getStatusColor(statusGroup, status) {
      return i18nDiclinaService.getDiclinaStatusStyle(
        statusGroup,
        status.replace("status_", "")
      ).color;
    },

    autocompleteStatusFilter(item, queryText, itemText, statusGroup) {
      return this.getStatusName(statusGroup.value.key, item)
        .toLowerCase()
        .includes(queryText.toLowerCase());
    },

    remove(item) {
      if (this.multiple) {
        const index = this.content.indexOf(item);
        if (index >= 0) this.content.splice(index, 1);
      } else {
        this.content = null;
      }

      this.$emit("change");
    },
  },
};
</script>

<style scoped></style>
