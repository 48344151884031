<template>
  <div>
    {{ address.streetName }} {{ address.houseNumber
    }}<span v-if="address.postalCode" class="text-muted"
      >, {{ address.postalCode }} {{ address.city }}
    </span>
  </div>
</template>

<script>
export default {
  name: "AddressDisplay",
  props: {
    address: {
      type: Object,
      required: true,
    },
    zipCode: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
