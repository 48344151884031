import ColorService from "@/services/colors/color.service";

class ClusterMarkerSingleService {
  static getCount(cluster) {
    return cluster.nAddresses;
  }

  static getSections(cluster) {
    return [
      {
        label: "Gesamt: " + cluster.nAddresses,
        value: 100,
        color: ColorService.getTransparentColorByName("blue", 0.6),
      },
    ];
  }
}

export default ClusterMarkerSingleService;
