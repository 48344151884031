import MarkerHelperService from "@/services/gmap/marker-helper.service";
import FilterHelperService from "@/services/filter-helper.service";
import status_i18n from "@/config/i18n/status";
import { shallowEqual } from "fast-equals";
import ClusterMarkerDefaultService from "@/services/gmap/clusterMarkerSectionServices/cluster-marker-default.service";
import ClusterMarkerSingleService from "@/services/gmap/clusterMarkerSectionServices/cluster-marker-single.service";
import ClusterMarkerHomesContractedService from "@/services/gmap/clusterMarkerSectionServices/cluster-marker-homes-contracted.service";
// import ClusterMarkerHomesConnectedService from "@/services/gmap/clusterMarkerSectionServices/cluster-marker-homes-connected.service";
import ClusterMarkerSingleAddressHomesContractedService from "@/services/gmap/clusterMarkerSectionServices/cluster-marker-single-address-homes-contracted.service";
// import ClusterMarkerSingleAddressHomesConnectedService from "@/services/gmap/clusterMarkerSectionServices/cluster-marker-single-address-homes-connected.service";

const CLUSTER_TYPES = ["county", "cluster", "city", "cityDistrict", "street"];

class MapMarkerService {
  constructor(map) {
    this.map = map;
    this.markers = [];
    this.infoWindows = [];
    this.filterHelperService = new FilterHelperService();
  }

  addMarker(address, title, style) {
    const marker = MarkerHelperService.createMarker(
      address,
      title,
      style,
      this.map
    );

    const infoWindow = this.addMarkerInfoWindow(address, marker);

    this.markers.push({ marker, infoWindow, address });
  }

  addClusterMarker(address, addressCount, sections) {
    const marker = MarkerHelperService.createClusterMarker(
      address,
      addressCount,
      sections,
      this.map
    );

    const infoWindow = this.addClusterMarkerInfoWindow(address, marker);

    this.markers.push({ marker, infoWindow, address });
  }

  addSingleAddressClusterMarker(address, addressCount, sections) {
    const marker = MarkerHelperService.createSingleAddressClusterMarker(
      address,
      addressCount,
      sections,
      this.map
    );

    const infoWindow = this.addMarkerInfoWindow(address, marker);

    this.markers.push({ marker, infoWindow, address });
  }

  addClusterMarkerInfoWindow(address, clusterMarker) {
    const clusterPixelSize = MarkerHelperService.getClusterPixelSize(
      address.nAddresses
    );

    let infoWindow = new window.google.maps.InfoWindow({
      pixelOffset: new window.google.maps.Size(0, -(clusterPixelSize / 2)),
    });

    let infoWindows = this.infoWindows;

    window.google.maps.event.addListener(infoWindow, "closeclick", function () {
      infoWindow.close();
      infoWindows.splice(infoWindows.indexOf(infoWindow), 1);
    });

    window.google.maps.event.addListener(clusterMarker, "click", function () {
      infoWindow.setContent(
        MarkerHelperService.createClusterInfoWindow(address)
      );
      infoWindow.setOptions({
        zIndex: 1,
      });
      infoWindow.open(this.map, clusterMarker);

      infoWindows.push(infoWindow);
    });

    return infoWindow;
  }

  addMarkerInfoWindow(address, marker) {
    let infoWindow = new window.google.maps.InfoWindow();
    let infoWindows = this.infoWindows;

    window.google.maps.event.addListener(marker, "click", function () {
      infoWindow.setContent(MarkerHelperService.createInfoWindow(address));
      infoWindow.open(this.map, marker);

      infoWindows.push(infoWindow);
    });

    window.google.maps.event.addListener(infoWindow, "closeclick", function () {
      infoWindow.close();
      infoWindows.splice(infoWindows.indexOf(infoWindow), 1);
    });

    return infoWindow;
  }

  // todo: this function has to be refactored before it becomes to big to handle
  addCorrectAddressMarkerType(address) {
    if (CLUSTER_TYPES.includes(address["@type"])) {
      let clusterMarkerService;

      if (this.filterHelperService.getIsSingleStatusSelected()) {
        const filterHelperService = new FilterHelperService();
        const statusKey = filterHelperService.getSingleSelectedStatusKey();

        switch (statusKey) {
          case "homesContracted":
            clusterMarkerService = ClusterMarkerHomesContractedService;
            break;
          // case "homesConnected":
          //   clusterMarkerService = ClusterMarkerHomesConnectedService;
          //   break;
          default:
            clusterMarkerService = ClusterMarkerDefaultService;
            break;
        }
      } else {
        clusterMarkerService = ClusterMarkerSingleService;
      }

      if (clusterMarkerService) {
        const sections = clusterMarkerService.getSections(address);
        let addressCount = clusterMarkerService.getCount(address);

        if (addressCount > 0) {
          // render cluster-marker
          this.addClusterMarker(address, addressCount, sections);
        }
      }
    } else if (address["@type"] === "address") {
      if (this.filterHelperService.getIsSingleStatusSelected()) {
        if (
          this.filterHelperService.getSingleSelectedStatusKey() ===
          "homesContracted"
        ) {
          const clusterMarkerService =
            ClusterMarkerSingleAddressHomesContractedService;
          const sections = clusterMarkerService.getSections(address);
          let addressCount = clusterMarkerService.getCount(address);

          if (addressCount > 0) {
            // render cluster-marker
            this.addSingleAddressClusterMarker(address, addressCount, sections);
          }
          // } else if (
          //   this.filterHelperService.getSingleSelectedStatusKey() ===
          //   "homesConnected"
          // ) {
          //   const clusterMarkerService =
          //     ClusterMarkerSingleAddressHomesConnectedService;
          //   const sections = clusterMarkerService.getSections(address);
          //   let addressCount = clusterMarkerService.getCount(address);
          //
          //   if (addressCount > 0) {
          //     // render cluster-marker
          //     this.addSingleAddressClusterMarker(address, addressCount, sections);
          //   }
        } else {
          let filterStatus =
            this.filterHelperService.getFilter()[
              this.filterHelperService.getSingleSelectedStatusKey()
            ];
          if (
            filterStatus.includes(
              address.status[
                this.filterHelperService.getSingleSelectedStatusKey()
              ].status.key
            )
          ) {
            // render address-marker
            this.addMarker(
              address,
              address.streetName + " " + address.houseNumber,
              status_i18n.STATUS_GROUP[
                this.filterHelperService.getSingleSelectedStatusKey()
              ].values[
                address.status[
                  this.filterHelperService.getSingleSelectedStatusKey()
                ].status.key
              ].color
            );
          }
        }
      } else {
        if (
          this.filterHelperService.addressStatusMatchesFilter(address.status)
        ) {
          // render address-marker
          this.addMarker(
            address,
            address.streetName + " " + address.houseNumber,
            "blue"
          );
        }
      }
    }
  }

  updateMarkers(addressData, replaceAll = false) {
    this.markers.map((marker) => {
      marker.deleteMe = true;
    });

    for (let i = 0; i < addressData.length; i++) {
      const address = addressData[i];

      const marker = getMarkerByAddress(address, this.markers);

      if (!marker || replaceAll) {
        this.addCorrectAddressMarkerType(address);
      } else {
        marker.deleteMe = false;
      }
    }

    this.markers.map((marker) => {
      if (marker.deleteMe) {
        this.removeMarker(marker);
      }
    });

    this.markers = this.markers.filter((marker) => !marker.deleteMe);
  }

  replaceMarkers(addressData) {
    this.removeMarkers();
    this.updateMarkers(addressData);
  }

  removeMarker(marker) {
    if (marker.marker instanceof window.google.maps.OverlayView) {
      marker.marker.remove();
      this.removeInfoWindow(marker.infoWindow);
    } else {
      marker.marker.setVisible(false);
      marker.marker.setMap(null);
      this.removeInfoWindow(marker.infoWindow);
    }
  }

  removeInfoWindow(infoWindow) {
    infoWindow.close();
    this.infoWindows.splice(this.infoWindows.indexOf(infoWindow), 1);
    infoWindow = null;
  }

  removeMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.removeMarker(this.markers[i]);
    }
    this.markers.length = 0;
  }
}

const getMarkerByAddress = (address, markerArray) => {
  if (CLUSTER_TYPES.includes(address["@type"])) {
    return markerArray.find(
      (marker) =>
        marker.marker instanceof window.google.maps.OverlayView &&
        marker.address["@type"] === address["@type"] &&
        shallowEqual(marker.address.center, address.center) &&
        marker.address.label === address.label &&
        marker.address.nAddresses === address.nAddresses
    );
  } else {
    return markerArray.find((marker) => address.ident === marker.address.ident);
  }
};

export default MapMarkerService;
