<template>
  <div>
    <div class="row">
      <div class="col-12 text-center text--secondary">
        Die Daten sind nicht immer auf dem neusten Stand, werden aber regelmäßig
        aktualisiert.<br />
        Die Informationen sind nur für den internen Gebrauch bestimmt und dürfen
        nicht an Dritte weitergegeben werden.
      </div>
    </div>

    <!--begin::map-->
    <v-row style="max-height: 80vh">
      <v-col cols="12" md="3" class="mb-md-0 mb-8 pr-lg-0 pr-4">
        <v-card class="fill-height" elevation="4" tile outlined>
          <v-tabs v-model="currentTab">
            <v-tab>
              Adress-Filter
              <v-badge v-show="filterActive" dot inline color="info" />
            </v-tab>
            <v-tab>
              Karten-Tools
              <v-badge v-show="mapToolsActive" dot inline color="info" />
            </v-tab>
          </v-tabs>

          <v-card-text class="scrollable">
            <v-tabs-items v-model="currentTab">
              <v-tab-item>
                <map-filter
                  ref="filter"
                  @changeMapView="currentMapView = arguments[0]"
                  @refreshMap="refreshMap"
                  @centerLocation="centerLocation"
                  @centerToHomeView="centerToHomeView"
                  @change="filterActive = arguments[0]"
                />
              </v-tab-item>
              <v-tab-item>
                <map-tools
                  @refreshMap="mapToolChanged(arguments[0])"
                  @change="mapToolsActive = arguments[0]"
                  @emptyFilter="emptyFilter"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="9" class="pl-lg-0 pl-4">
        <v-progress-linear
          v-show="loadingMapView"
          indeterminate
          color="primary"
        />
        <google-map
          class="elevation-4"
          ref="map"
          :headers="dataTable.headers"
          @loadingMapView="setLoadingMapView(arguments[0])"
        />
      </v-col>
    </v-row>
    <!--end::map-->

    <!-- begin::datatable -->
    <div v-if="hasNoGroups" class="row mt-10">
      <div class="col-12">
        <address-data-table
          :headers="dataTable.headers"
          ref="table"
          item-key="address.id"
          class="elevation-4"
          @center-location="centerLocation"
        />
      </div>
    </div>
    <!-- end::datatable -->
  </div>
</template>

<script>
import GoogleMap from "../components/GoogleMap";

import MapFilter from "./addresses/filter/MapFilter";
import AddressDataTable from "./addresses/datatable/AddressDataTable";
import MapTools from "./addresses/filter/MapTools";
import { mapGetters } from "vuex";

export default {
  name: "Addresses",
  components: {
    MapTools,
    AddressDataTable,
    MapFilter,
    GoogleMap,
  },
  data() {
    return {
      currentTab: null,
      currentMapView: "marker",
      status: [],
      selectedStatus: [],
      selectedAddresses: [],
      locationsCount: 0,
      loadingMapView: false,
      filterActive: false,
      mapToolsActive: false,
    };
  },
  computed: {
    ...mapGetters(["userGroups"]),
    hasNoGroups() {
      return this.userGroups.data?.length === 0;
    },
    dataTable() {
      let headers = [
        {
          text: "Straße",
          align: "left",
          value: "streetName",
        },
        { text: "Hausnr.", value: "houseNumber" },
        { text: "PLZ", value: "postalCode" },
        { text: "Stadt", value: "city" },
        { text: "Landkreis", value: "countyName" },
        { text: "Anz. WE", value: "stats.amountHousingUnits" },
        { text: "Anz. GE", value: "stats.amountBusinessUnits" },
        { text: "", value: "actions" },
      ];

      return {
        headers,
        items: this.locations,
        selectedAddresses: [],
      };
    },
  },
  created() {
    this.$store.dispatch("updateStatus");

    this.$root.$on("set-loading-map-view", this.setLoadingMapView);
    this.$root.$on("center-geometry", this.centerGeometry);
  },
  beforeDestroy() {
    this.$store.dispatch("clearVisibleAddresses");
  },
  methods: {
    async loadAddresses() {
      await this.$store.dispatch("updateAddresses", true);
    },
    setLoadingMapView(loadingMapView) {
      this.loadingMapView = loadingMapView;
    },
    mapToolChanged(loadingMapView) {
      this.applyFilter();
      this.setLoadingMapView(loadingMapView);
    },
    refreshMap() {
      this.$refs.map.startRefreshMap(true);
      this.$refs.table.updateAddresses();
    },
    centerLocation(location) {
      this.$refs.map.centerLocation(location);
    },
    centerGeometry(geometry) {
      this.$refs.map.centerGeometry(geometry);
    },
    centerToHomeView() {
      this.$refs.map.centerToHomeView();
    },
    applyFilter() {
      this.$refs.filter.saveFilter();
    },
    emptyFilter() {
      this.$refs.filter.emptyFilter();
    },
  },
};
</script>

<style lang="scss">
.scrollable {
  height: calc(80vh - 48px);
  overflow-y: auto;
}
</style>
