import Vue from "vue";
import PieClusterMarker from "../../components/PieClusterMarker";
import createHTMLMapMarker from "./mapviews/HTMLMapMarker";
import MarkerInfoWindow from "@/components/MarkerInfoWindow";
import ClusterMarkerInfoWindow from "@/components/ClusterMarkerInfoWindow";

class MarkerHelperService {
  static createMarker(address, title, style, map = null) {
    const icon = require("../../../public/media/marker/Marker_" +
      style +
      ".png");

    return new window.google.maps.Marker({
      position: address.location,
      map,
      shadow: null,
      icon,
      shape: null,
      title,
      zIndex: 1,
      opacity: 1,
    });
  }

  static createClusterMarker(address, addressCount, sections, map = null) {
    let ComponentClass = Vue.extend(PieClusterMarker);
    const clusterPixelSize = this.getClusterPixelSize(addressCount);

    let instance = new ComponentClass({
      propsData: {
        count: addressCount,
        size: clusterPixelSize,
        sections,
      },
    });
    instance.$mount();

    const element = instance.$el.innerHTML;
    const latLng = new window.google.maps.LatLng(
      address.center.lat,
      address.center.lng
    );

    return createHTMLMapMarker({
      position: latLng,
      map: map,
      html: element,
    });
  }

  static createSingleAddressClusterMarker(
    address,
    addressCount,
    sections,
    map = null
  ) {
    let ComponentClass = Vue.extend(PieClusterMarker);
    const clusterPixelSize = this.getClusterPixelSize(addressCount);

    let instance = new ComponentClass({
      propsData: {
        count: addressCount,
        size: clusterPixelSize,
        sections,
      },
    });
    instance.$mount();

    const element = instance.$el.innerHTML;
    const latLng = new window.google.maps.LatLng(
      address.location.lat,
      address.location.lng
    );

    return createHTMLMapMarker({
      position: latLng,
      map: map,
      html: element,
    });
  }

  static createInfoWindow(address) {
    let InfoWindow = Vue.extend(MarkerInfoWindow);
    let instance = new InfoWindow({
      propsData: { address },
    });

    instance.$mount();

    return instance.$el;
  }

  static createClusterInfoWindow(cluster) {
    let InfoWindow = Vue.extend(ClusterMarkerInfoWindow);
    let instance = new InfoWindow({
      propsData: { cluster },
    });

    instance.$mount();

    return instance.$el;
  }

  static createInvisibleMarker(location, map) {
    return new window.google.maps.Marker({
      position: location,
      map,
      shadow: null,
      shape: null,
      zIndex: 1,
      opacity: 1,
    });
  }

  static getClusterPixelSize(addressCount) {
    return (addressCount + "").length * 7 + 30;
  }
}

export default MarkerHelperService;
