<template>
  <div style="width: 400px">
    <span class="title">
      {{ address.streetName }} {{ address.houseNumber }}
    </span>
    <br />
    <span class="subtitle-1">{{ address.postalCode }} {{ address.city }}</span>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>Wohneinheiten</td>
            <td>{{ address.stats.amountHousingUnits }}</td>
          </tr>
          <tr>
            <td>Geschäftseinheiten</td>
            <td>{{ address.stats.amountBusinessUnits }}</td>
          </tr>
          <tr>
            <td>Vertragsstatus</td>
            <td>
              <pie-cluster-marker
                :count="homesContractedSum"
                :sections="homesContractedSections"
              />
            </td>
          </tr>
          <tr v-for="(status, key) in filteredAddressStatus" :key="key">
            <td>{{ getStatusLabel(key) }}</td>
            <td>
              <status-chip :status="status.status.key" :status-group="key" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import StatusChip from "@/components/StatusChip";
import status_i18n from "@/config/i18n/status";
import PieClusterMarker from "@/components/PieClusterMarker";
import ColorService from "@/services/colors/color.service";

export default {
  name: "MarkerInfoWindow",
  components: { PieClusterMarker, StatusChip },
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filteredAddressStatus() {
      const raw = this.address.status;
      const notAllowed = ["homesContracted"];

      return Object.keys(raw)
        .filter((key) => !notAllowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = raw[key];
          return obj;
        }, {});
    },
    homesContractedSum() {
      return (
        this.address.stats.amountContractNotOffered +
        this.address.stats.amountContractOffered +
        this.address.stats.amountContractRejected +
        this.address.stats.amountContractSigned
      );
    },
    homesContractedSections() {
      let sections = [];

      const contractedStatusKeyTranslation = {
        not_offered: "amountContractNotOffered",
        offered: "amountContractOffered",
        rejected: "amountContractRejected",
        signed: "amountContractSigned",
      };

      for (const [key, value] of Object.entries(
        status_i18n.STATUS_GROUP.homesContracted.values
      )) {
        const percent =
          (this.address.stats[contractedStatusKeyTranslation[key]] /
            this.homesContractedSum) *
          100;

        sections.push({
          label:
            value.label +
            ": " +
            this.address.stats[contractedStatusKeyTranslation[key]] +
            " (" +
            Math.round(percent * 100) / 100 +
            "%)",
          value: percent,
          color: ColorService.getTransparentColorByName(
            status_i18n.STATUS_GROUP.homesContracted.values[key].color,
            0.6
          ),
        });
      }

      return sections;
    },
  },
  methods: {
    getStatusLabel(statusGroup) {
      const statusLabel = status_i18n.STATUS_GROUP[statusGroup]
        ? status_i18n.STATUS_GROUP[statusGroup].label
        : statusGroup;
      return this.label ?? statusLabel;
    },
  },
};
</script>

<style scoped></style>
