import store from "../store/index";

class FilterHelperService {
  getFilter() {
    return store.getters.filter.status;
  }

  addressStatusMatchesFilter(addressStatus) {
    const statusFilter = this.getFilter();

    if (statusFilter) {
      for (const [key, value] of Object.entries(statusFilter)) {
        if (
          value.length > 0 &&
          !value.includes(addressStatus[key].status.key)
        ) {
          return false;
        }
      }
    }

    return true;
  }

  getIsSingleStatusSelected() {
    const statusFilter = this.getFilter();
    let counter = 0;

    if (statusFilter) {
      for (const statusGroup of Object.entries(statusFilter)) {
        if (statusGroup[1].length > 0) {
          counter++;
        }
      }
    }

    return counter === 1;
  }

  getSingleSelectedStatusKey() {
    const statusFilter = this.getFilter();

    if (statusFilter) {
      for (const statusGroup of Object.entries(statusFilter)) {
        if (statusGroup[1].length > 0) {
          return statusGroup[0];
        }
      }
    }

    return null;
  }
}

export default FilterHelperService;
