<template>
  <div>
    <v-alert text icon="mdi-information-outline">
      <span class="ml-4"> Die Heatmap-Ansicht ist aktiv. </span>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "ToolSettingsHeatmap",
  data() {
    return {
      heatmapToggled: true,
      heatmaps: [],
    };
  },
  methods: {
    addHeatmap(heatmap) {
      this.heatmaps.push(heatmap);
    },
    removeHeatmaps() {
      for (let i = 0; i < this.heatmaps.length; i++) {
        let heatmap = this.heatmaps[i];
        heatmap.resetMarker();
      }

      this.heatmaps = [];
      this.$root.$emit("deactivateHeatmap");
    },
  },
  created() {
    this.$emit("change", true);
    this.$root.$emit("activateHeatmap");
    this.$root.$on("createdHeatmapMapView", this.addHeatmap);
  },
  beforeDestroy() {
    this.$emit("change", false);
    this.removeHeatmaps();
  },
};
</script>

<style scoped></style>
