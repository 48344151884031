import * as helpers from "@turf/helpers";
import envelope from "@turf/envelope";

class GeocoderHelperService {
  constructor(geocoder) {
    this.geocoder = geocoder;
  }

  async getLocationByName(search) {
    return this.getGeometryByName(search).location;
  }

  async getGeometryByName(search) {
    let geometry = false;

    await this.geocoder
      .geocode({ address: search }, function (results, status) {
        if (status !== "OK" || !results[0]) {
          throw new Error(status);
        }
      })
      .then(({ results }) => {
        geometry = results[0].geometry;
      });

    return geometry;
  }

  async getGeometriesByName(search) {
    return await this.geocoder.geocode({ address: search });
  }

  async getPolygonOfLocations(locationNameArray) {
    let latLngArray = [];
    for (let i = 0; i < locationNameArray.length; i++) {
      const geometry = await this.getGeometryByName(locationNameArray[i]);

      const ne = geometry.viewport.getNorthEast();
      const sw = geometry.viewport.getSouthWest();

      latLngArray.push(helpers.point([ne.lat(), ne.lng()]));
      latLngArray.push(helpers.point([sw.lat(), sw.lng()]));
    }

    return envelope(helpers.featureCollection(latLngArray));
  }

  async getPolygonOfHulls(locationHullArray) {
    let latLngArray = [];
    for (let i = 0; i < locationHullArray.length; i++) {
      const hull = locationHullArray[i];

      latLngArray = latLngArray.concat(getTurfPointArray(hull));
    }

    return envelope(helpers.featureCollection(latLngArray));
  }
}

const getTurfPointArray = (array) => {
  let result = [];

  for (let i = 0; i < array.length; i++) {
    const latLng = array[i];

    if (latLng.length === 2 && typeof latLng[0] == "number") {
      result.push(helpers.point([latLng[1], latLng[0]]));
    } else {
      result = result.concat(getTurfPointArray(latLng));
    }
  }

  return result;
};

export default GeocoderHelperService;
