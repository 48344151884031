<template>
  <div>
    <v-list>
      <v-list-item-group v-model="mapTool">
        <v-list-item
          v-for="tool in mapTools"
          :key="tool.key"
          :disabled="tool.disabled"
        >
          <v-list-item-icon class="mr-5">
            <v-icon v-text="tool.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="tool.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <div class="pt-7">
      <component
        v-if="typeof mapTool !== 'undefined' && mapTool !== null"
        :is="toolSettings"
        @change="handleChange"
      />
    </div>
  </div>
</template>

<script>
import ToolSettingsRoute from "./tools/ToolSettingsRoute";
import ToolSettingsPerimeter from "./tools/ToolSettingsPerimeter";
import ToolSettingsPolygon from "./tools/ToolSettingsPolygon";
import ToolSettingsHeatmap from "./tools/ToolSettingsHeatmap";
import ToolSettingsAddress from "./tools/ToolSettingsAddress";

export default {
  name: "MapTools",
  components: {
    ToolSettingsRoute,
    ToolSettingsPerimeter,
    ToolSettingsPolygon,
    ToolSettingsHeatmap,
    ToolSettingsAddress,
  },
  data() {
    return {
      mapTool: null,
      mapTools: [
        {
          key: "address",
          name: "Address-Suche",
          icon: "mdi-magnify",
          disabled: false,
        },
        {
          key: "polygon",
          name: "Polygon-Tool",
          icon: "mdi-vector-square",
          disabled: false,
        },
        {
          key: "perimeter",
          name: "Umkreis-Suche",
          icon: "mdi-map-marker-radius",
          disabled: false,
        },
        {
          key: "route",
          name: "Routen-Suche",
          icon: "mdi-highway",
          disabled: false,
        },
        {
          key: "heatmap",
          name: "Heatmap",
          icon: "mdi-thermometer-lines",
          disabled: true,
        },
      ],
      toolActive: false,
    };
  },
  computed: {
    toolSettings() {
      return "tool-settings-" + this.mapTools[this.mapTool].key;
    },
  },
  methods: {
    handleChange(toolActive) {
      this.toolActive = toolActive;
      this.emitChange();

      if (toolActive) {
        this.refreshMap();
      } else {
        this.emitEmptyFilter();
      }
    },
    refreshMap() {
      this.$emit("refreshMap", true);
    },
    emitChange() {
      this.$emit("change", this.toolActive);
    },
    emitEmptyFilter() {
      this.$emit("emptyFilter");
    },
  },
  watch: {
    mapTool() {
      const mapTool = this.mapTools[this.mapTool]
        ? this.mapTools[this.mapTool].key
        : null;

      this.$root.$emit("toolChanged", mapTool);
      this.handleChange(false);
    },
  },
};
</script>

<style scoped></style>
