<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="userInput"
          append-icon="mdi-magnify"
          placeholder="Adresse suchen"
          clearable
          @click:append="searchPoints"
          @click:clear="results = []"
          @keydown="handleKeyInput"
        >
        </v-text-field>
      </v-col>
      <v-col v-if="errorMessage" cols="auto" class="mt-n6">
        <span>{{ errorMessage }}</span>
      </v-col>
    </v-row>
    <v-row v-if="results.length" class="mt-n4">
      <v-col>
        <v-list v-for="result in results" :key="result.index" outlined>
          <v-list-item v-if="result.formatted_address.length < 30">
            <v-list-item-content>
              <v-list-item-title>
                {{ result.formatted_address }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="centerOnPoint(result)">
                <v-icon>mdi-crosshairs-gps</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-else two-line>
            <v-list-item-content>
              <v-list-item-title>
                {{ result.formatted_address.split(", ")[0] }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{
                  result.formatted_address.substring(
                    result.formatted_address.indexOf(", ") + 1
                  )
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="centerOnPoint(result)">
                <v-icon>mdi-crosshairs-gps</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GeocoderHelperService from "@/services/gmap/geocoder-helper.service";

export default {
  name: "ToolSettingsAddress",
  data() {
    return {
      userInput: "",
      geocoderHelperService: null,
      errorMessage: "",
      results: [],
    };
  },
  mounted() {
    const geocoder = new window.google.maps.Geocoder();
    this.geocoderHelperService = new GeocoderHelperService(geocoder);
  },
  methods: {
    searchPoints: async function () {
      this.errorMessage = "";
      this.results = [];
      await this.geocoderHelperService
        .getGeometriesByName(this.userInput)
        .then((data) => {
          this.centerOnPoint(data.results[0]);
          this.results = data.results;
        })
        .catch((error) => {
          if (error.code === "ZERO_RESULTS") {
            this.errorMessage = "Kein Ergebnis gefunden";
          } else if (!this.userInput) {
            this.errorMessage = "Bitte geben Sie einen Ort an";
          } else {
            this.errorMessage =
              "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut";
          }
          return error;
        });
    },
    centerOnPoint: function (point) {
      this.$root.$emit("center-geometry", point.geometry);
    },
    handleKeyInput: function (event) {
      if (event.key === "Enter") {
        this.searchPoints();
      }
    },
  },
};
</script>

<style scoped></style>
