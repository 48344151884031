<template>
  <div
    id="map_canvas"
    class="shadow-sm rounded-left-0"
    style="width: 100%; height: 80vh"
    ref="map"
  ></div>
</template>

<script>
import gmap from "@/services/gmap/gmap.service";
import MapHelperService from "@/services/gmap/map-helper.service";
import TurfHelperService from "@/services/turf-helper.service";
import Vue from "vue";

import markerMapView from "@/services/gmap/mapviews/marker";
import heatmapMapView from "@/services/gmap/mapviews/heatmap";
import store from "@/store";
import MapMarkerService from "@/services/gmap/mapviews/map-marker-service";
import AddressTransformerService from "@/services/data/address-transformer.service";
import GeocoderHelperService from "@/services/gmap/geocoder-helper.service";
import ApiService from "@/services/api.service";
import FilterHelperService from "@/services/filter-helper.service";

let viewportChangedTimeout = null;
window.curentZoom = 25;

const MAP_VIEW_HEATMAP = "heatmap";
const MAP_VIEW_MARKER = "marker";

const ADDRESS_ROUTES = {
  county: "sales-control/by-route/cluster/county",
  cluster: "sales-control/by-route/cluster/cluster",
  city: "sales-control/by-route/cluster/city",
  cityDistrict: "sales-control/by-route/cluster/cityDistrict",
  street: "sales-control/by-route/cluster/street",
  address: "sales-control/by-route",
};

const ADDRESS_ROUTE_BREAKPOINTS = [
  {
    breakpoint: 12000,
    route: ADDRESS_ROUTES.county,
  },
  {
    breakpoint: 1100,
    route: ADDRESS_ROUTES.city,
  },
  {
    breakpoint: 60,
    route: ADDRESS_ROUTES.cityDistrict,
  },
  {
    breakpoint: 1,
    route: ADDRESS_ROUTES.street,
  },
  {
    breakpoint: 0,
    route: ADDRESS_ROUTES.address,
  },
];

export default {
  name: "GoogleMap",
  props: ["headers"],
  data() {
    return {
      map: null,
      homePosition: null,
      drawingManager: null,
      directionsService: null,
      activatedMapView: MAP_VIEW_MARKER,
      mapMarkerService: null,
      geocoderHelperService: null,
      currentRenderProcesses: [],
    };
  },

  computed: {
    mapView() {
      let mapView;
      switch (this.activatedMapView) {
        case MAP_VIEW_HEATMAP:
          mapView = heatmapMapView;
          break;
        default:
          mapView = markerMapView;
      }

      mapView.setGoogle(window.google);
      mapView.setMap(this.map);
      mapView.setHeader(this.headers);

      return mapView;
    },
  },

  async mounted() {
    try {
      window.google = await gmap.init();
      const geocoder = new window.google.maps.Geocoder();
      this.map = new window.google.maps.Map(this.$el, gmap.mapOptions);
      let map = this.map;
      this.mapMarkerService = new MapMarkerService(map);
      this.geocoderHelperService = new GeocoderHelperService(geocoder);

      let accessRights = [];
      let locationNames = [];
      let locationBounds = [];

      await ApiService.get("sales-control/utils/access-rights").then(
        ({ data }) => {
          accessRights = data.data;
        }
      );

      if (accessRights.length > 0) {
        for (let i = 0; i < accessRights.length; i++) {
          locationNames.push(accessRights[i].value[0]);
          locationBounds.push(accessRights[i].hull);
        }
      }

      if (locationNames.length === 0) {
        this.homePosition = {
          type: "geometry",
          geoObject: await this.geocoderHelperService.getGeometryByName(
            process.env.VUE_APP_MAPS_DEFAULT_LOCATION + ", Germany"
          ),
        };
      } else if (locationNames.length === 1) {
        this.homePosition = {
          type: "geometry",
          geoObject: await this.geocoderHelperService.getGeometryByName(
            locationNames[0] + ", Germany"
          ),
        };
      } else {
        const polygon = await this.geocoderHelperService.getPolygonOfHulls(
          locationBounds
        );
        this.homePosition = {
          type: "polygon",
          geoObject: polygon,
        };
      }

      await this.centerToHomeView();

      const startRefreshMap = this.startRefreshMap;

      setTimeout(() => {
        map.addListener("bounds_changed", async function () {
          clearTimeout(viewportChangedTimeout);

          viewportChangedTimeout = setTimeout(() => {
            startRefreshMap();
          }, 1500);
        });
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  },

  created() {
    this.$root.$on("toolChanged", this.createDrawingTools);
    this.$root.$on("drawNewPolygon", this.drawNewPolygon);
    this.$root.$on("drawNewCircle", this.drawNewCircle);
    this.$root.$on("generateNewRouteSearch", this.generateNewRouteSearch);
    this.$root.$on("abortDrawingNewPolygon", this.abortDrawingNewPolygon);
    this.$root.$on("initDirectionService", this.initDirectionService);
    this.$root.$on("activateHeatmap", this.activateHeatmap);
    this.$root.$on("deactivateHeatmap", this.deactivateHeatmap);
  },

  destroyed() {
    this.mapMarkerService.removeMarkers();
  },

  methods: {
    async centerLocation(location) {
      const map = this.map;

      const mapHelperService = new MapHelperService(map);
      mapHelperService.centerLocation(location);
      mapHelperService.setZoom(18);
    },

    async centerGeometry(geoObject) {
      const map = this.map;
      const mapHelperService = new MapHelperService(map);

      mapHelperService.centerGeometry(geoObject);
    },

    async centerToHomeView() {
      const map = this.map;
      const mapHelperService = new MapHelperService(map);

      if (this.homePosition.type === "geometry") {
        mapHelperService.centerGeometry(this.homePosition.geoObject);
      } else if (this.homePosition.type === "polygon") {
        mapHelperService.fitBoundsFromPolygon(this.homePosition.geoObject);
      }
    },

    setCurrentRenderProcess(renderProcess) {
      for (let i = 0; i < this.currentRenderProcesses.length; i++) {
        this.currentRenderProcesses[i].axiosToken.cancel();
      }
      this.currentRenderProcesses.length = 0;

      this.currentRenderProcesses.push(renderProcess);
    },

    async getAddressData(cancelTokenSource, retries = 0) {
      const map = this.map;

      const mapHelperService = new MapHelperService(map);
      let polygon = [];

      if (this.$store.getters.polygons.length > 0) {
        const turfHelperService = new TurfHelperService();
        let route = [];
        const box = mapHelperService.getMapBBoxPolygon();

        this.$store.getters.polygons[0].polygon.getPaths().forEach((p) => {
          p.getArray().forEach((pt) => {
            route.push([pt.lat(), pt.lng()]);
          });
        });

        const intersect = turfHelperService.intersectCoordinates(
          route,
          box.geometry.coordinates[0]
        );

        if (intersect) {
          polygon = intersect.geometry.coordinates[0].map((arr) => ({
            lat: arr[0],
            lng: arr[1],
          }));
        }
      } else if (this.$store.getters.circles.length > 0) {
        const turfHelperService = new TurfHelperService();
        const box = mapHelperService.getMapBBoxPolygon();

        const circle = turfHelperService.createCircle(
          [
            this.$store.getters.circles[0].circle.center.lat(),
            this.$store.getters.circles[0].circle.center.lng(),
          ],
          this.$store.getters.circles[0].circle.radius / 1000
        );

        const intersect = turfHelperService.intersectCoordinates(
          circle.geometry.coordinates[0],
          box.geometry.coordinates[0]
        );

        polygon = intersect.geometry.coordinates[0].map((arr) => ({
          lat: arr[0],
          lng: arr[1],
        }));
      } else {
        polygon = mapHelperService.getMapBBoxCoordinates();
      }

      let nodes;

      const params = {
        size: 1000, // limit address number to 1000
        page: 1,
        route: JSON.stringify(polygon),
        cancelToken: cancelTokenSource.token,
      };

      const filterHelperService = new FilterHelperService();
      const activeStatus = filterHelperService.getSingleSelectedStatusKey();

      if (activeStatus !== "homesContracted") {
        this.$parent.$store.getters.webFilterArray().forEach((item) => {
          params[item.key] = item.value;
        });
      }

      const sqKm = mapHelperService.getMapBBoxArea();
      const route = ADDRESS_ROUTE_BREAKPOINTS.find(
        (obj) => obj.breakpoint <= sqKm
      ).route;

      const getAddressData = this.getAddressData;

      await Vue.axios
        .get(route, { params })
        .then(({ data }) => {
          nodes = data;
        })
        .catch((e) => {
          if (!e.response && retries < 10) {
            getAddressData(cancelTokenSource, retries + 1);
          } else if (e.response.status === 403) {
            store.dispatch("setTokenTimedOut", true);
          } else if (Vue.axios.isCancel(e)) {
            console.log("Request canceled", e.message);
          }

          throw new Error("failed fetching addresses");
        });

      return nodes.data;
    },

    async startRefreshMap(replaceAll = false) {
      this.$emit("loadingMapView", true);

      const cancelTokenSource = Vue.axios.CancelToken.source();
      const renderProcess = {
        id: Date.now(),
        axiosToken: cancelTokenSource,
      };
      this.setCurrentRenderProcess(renderProcess);

      const map = this.map;

      if (map.getBounds()) {
        let nodes = await this.getAddressData(cancelTokenSource);

        if (
          this.currentRenderProcesses.find(
            (proc) => proc.id === renderProcess.id
          )
        ) {
          let newMarkers =
            AddressTransformerService.transformAddressData(nodes);

          this.mapMarkerService.updateMarkers(newMarkers, replaceAll);

          this.$emit("loadingMapView", false);
        }
      }
    },

    refreshMap() {
      this.toggleMarkerMapView();
    },

    async activateHeatmap() {
      await this.mapView.resetMarker();

      this.activatedMapView = MAP_VIEW_HEATMAP;
      await this.startRefreshMap();
    },

    async deactivateHeatmap() {
      this.activatedMapView = MAP_VIEW_MARKER;
      await this.mapView.resetMarker();
    },

    toggleMarkerMapView() {
      this.$worker
        .run(
          async (arg) => {
            const mapView = JSON.parse(arg);

            await mapView.resetMarker();
            await mapView.initMarker();
          },
          [JSON.stringify(this.mapView)]
        )
        .then(() => {
          if (this.activatedMapView === MAP_VIEW_HEATMAP) {
            this.$root.$emit("createdHeatmapMapView", this.mapView);
          }
          this.$emit("loadingMapView", false);
        })
        .catch((e) => {
          console.error(e);
        });
    },

    createDrawingTools(selectedTool) {
      if (this.drawingManager) {
        this.drawingManager.setMap(null);
      }

      switch (selectedTool) {
        case "polygon":
        case "perimeter":
          this.initDrawingTool();
          break;
      }
    },

    initDrawingTool() {
      this.drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
        markerOptions: {
          draggable: true,
        },
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            // window.google.maps.drawing.OverlayType.MARKER,
            window.google.maps.drawing.OverlayType.CIRCLE,
            window.google.maps.drawing.OverlayType.POLYGON,
            // window.google.maps.drawing.OverlayType.POLYLINE,
            // window.google.maps.drawing.OverlayType.RECTANGLE,
          ],
        },
      });

      this.drawingManager.setMap(this.map);

      // const rootInstance = this.$root;
      const store = this.$store;
      const drawingManager = this.drawingManager;

      window.google.maps.event.addListener(
        this.drawingManager,
        "polygoncomplete",
        function (polygon) {
          polygon.setOptions({
            fillColor: "#f08500",
            strokeColor: "#f08500",
            editable: false,
          });

          // rootInstance.$emit("addedPolygon", polygon);
          store.dispatch("addPolygon", {
            data: {},
            polygon,
            addresses: [],
            count: 0,
          });
          drawingManager.setDrawingMode(null);
        }
      );

      window.google.maps.event.addListener(
        this.drawingManager,
        "circlecomplete",
        function (circle) {
          circle.setOptions({
            fillColor: "#f08500",
            strokeColor: "#f08500",
            editable: false,
          });

          // rootInstance.$emit("addedPolygon", polygon);
          store.dispatch("addCircle", {
            data: {},
            circle,
            addresses: [],
            count: 0,
          });
          drawingManager.setDrawingMode(null);
        }
      );
    },

    initDirectionService() {
      this.directionsService = new window.google.maps.DirectionsService();

      const directionService = this.directionsService;

      this.$root.$emit("createdDirectionService", directionService);
    },

    drawNewPolygon() {
      this.drawingManager.setDrawingMode(
        window.google.maps.drawing.OverlayType.POLYGON
      );
    },

    drawNewCircle() {
      this.drawingManager.setDrawingMode(
        window.google.maps.drawing.OverlayType.CIRCLE
      );
      // const map = this.map;
      //
      // const circle = new window.google.maps.Circle({
      //   // strokeColor: "#FF0000",
      //   // strokeOpacity: 0.8,
      //   // strokeWeight: 2,
      //   // fillColor: "#FF0000",
      //   // fillOpacity: 0.35,
      //   map,
      //   center: new window.google.maps.LatLng(
      //     data.centerAddress.point.lat,
      //     data.centerAddress.point.lng
      //   ),
      //   radius: data.radius,
      // });
      //
      // this.$root.$emit("addedCircle", circle);
    },

    generateNewRouteSearch(coordinates) {
      const polygon = new window.google.maps.Polygon({
        paths: coordinates,
        map: this.map,
      });

      this.$store.dispatch("deleteAllPolygons");

      this.$store.dispatch("addPolygon", {
        data: {},
        polygon,
        addresses: [],
        count: 0,
      });
    },

    abortDrawingNewPolygon() {
      this.drawingManager.setDrawingMode(null);
    },

    abortDrawingNewCirlce() {
      this.drawingManager.setDrawingMode(null);
    },
  },
  watch: {
    circles() {
      this.abortDrawingNewCirlce();
    },
  },
};
</script>

<style lang="scss"></style>
