import status_i18n from "@/config/i18n/status";
import ColorService from "@/services/colors/color.service";
import FilterHelperService from "@/services/filter-helper.service";

class ClusterMarkerDefaultService {
  static getCount(cluster) {
    let addressCount = 0;

    const addressStatus = getAddressStatus(cluster);
    const filterStatus = getFilterStatus(cluster);

    addressStatus.map((status) => {
      if (filterStatus.includes(status.statusValue.key)) {
        addressCount += status.n;
      }
    });

    return addressCount;
  }

  static getSections(cluster) {
    const filterHelperService = new FilterHelperService();

    let sections = [];
    let addressCount = this.getCount(cluster);

    const addressStatus = getAddressStatus(cluster);
    const filterStatus = getFilterStatus(cluster);

    if (addressCount > 0) {
      addressStatus.map((status) => {
        if (filterStatus.includes(status.statusValue.key)) {
          const percent = (status.n / addressCount) * 100;

          sections.push({
            label:
              status_i18n.STATUS_GROUP[
                filterHelperService.getSingleSelectedStatusKey()
              ].values[status.statusValue.key].label +
              ": " +
              status.n +
              " (" +
              Math.round(percent * 100) / 100 +
              "%)",
            value: percent,
            color: ColorService.getTransparentColorByName(
              status_i18n.STATUS_GROUP[
                filterHelperService.getSingleSelectedStatusKey()
              ].values[status.statusValue.key].color,
              0.6
            ),
          });
        }
      });
    }

    return sections;
  }
}

const getAddressStatus = (cluster) => {
  const filterHelperService = new FilterHelperService();
  return cluster.items.filter(
    (obj) => obj.statusKey === filterHelperService.getSingleSelectedStatusKey()
  );
};

const getFilterStatus = () => {
  const filterHelperService = new FilterHelperService();
  return filterHelperService.getFilter()[
    filterHelperService.getSingleSelectedStatusKey()
  ];
};

export default ClusterMarkerDefaultService;
