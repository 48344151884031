<template>
  <div>
    <v-select
      v-model="content"
      :items="items"
      :multiple="multiple"
      :label="itemLabel"
      item-text="label"
      item-value="key"
      clearable
      v-on="$listeners"
      @change="handleInput"
    >
      <template v-slot:selection="data">
        <status-chip
          :status="data.item.key"
          :status-group="statusGroup.name"
          :close="multiple"
          @click:close="remove(data.item.key)"
        />
      </template>
      <template v-slot:item="data">
        <v-list-item-avatar size="sm" class="mr-3">
          <v-img
            :src="
              '/media/marker/Marker_' + getStatusColor(data.item.key) + '.png'
            "
          />
        </v-list-item-avatar>
        <v-list-item-content v-text="getStatusName(data.item.key)" />
      </template>
    </v-select>
  </div>
</template>

<script>
import StatusChip from "./StatusChip";
import status_i18n from "../config/i18n/status";

export default {
  name: "StatusSelect",
  components: { StatusChip },

  props: {
    value: [Array, String],
    statusGroup: Object,
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      content: this.value,
    };
  },

  computed: {
    itemLabel() {
      const statusLabel = status_i18n.STATUS_GROUP[this.statusGroup.name]
        ? status_i18n.STATUS_GROUP[this.statusGroup.name].label
        : this.statusGroup.name;
      return this.label ?? statusLabel;
    },

    items() {
      const items = [];
      this.statusGroup.values.map((value) => {
        const newValue = value;
        value.label = status_i18n.STATUS_GROUP[this.statusGroup.name]
          ? status_i18n.STATUS_GROUP[this.statusGroup.name].values[value.key]
              .label
          : value.key;

        items.push(newValue);
      });

      return items;
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    getStatusName(status) {
      return status_i18n.STATUS_GROUP[this.statusGroup.name]
        ? status_i18n.STATUS_GROUP[this.statusGroup.name].values[status].label
        : status;
    },

    getStatusColor(status) {
      return status_i18n.STATUS_GROUP[this.statusGroup.name]
        ? status_i18n.STATUS_GROUP[this.statusGroup.name].values[status].color
        : "blue";
    },

    remove(item) {
      if (this.multiple) {
        const index = this.content.indexOf(item);
        if (index >= 0) this.content.splice(index, 1);
      } else {
        this.content = null;
      }

      this.$emit("change");
    },
  },
};
</script>

<style scoped></style>
