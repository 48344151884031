import status_i18n from "@/config/i18n/status";
import ColorService from "@/services/colors/color.service";
import FilterHelperService from "@/services/filter-helper.service";

class ClusterMarkerHomesContractedService {
  static getCount(cluster) {
    return getHomesContractedSum(cluster);
  }

  static getSections(cluster) {
    let sections = [];

    const homesContractedSum = getHomesContractedSum(cluster);

    for (const [key, value] of Object.entries(
      status_i18n.STATUS_GROUP.homesContracted.values
    )) {
      if (getFilterStatus().includes(key)) {
        const percent =
          (cluster[statusKeyTranslation[key]] / homesContractedSum) * 100;

        sections.push({
          label:
            value.label +
            ": " +
            cluster[statusKeyTranslation[key]] +
            " (" +
            Math.round(percent * 100) / 100 +
            "%)",
          value: percent,
          color: ColorService.getTransparentColorByName(
            status_i18n.STATUS_GROUP.homesContracted.values[key].color,
            0.6
          ),
        });
      }
    }

    return sections;
  }
}

const statusKeyTranslation = {
  not_offered: "nhc0",
  signed: "nhc1",
  rejected: "nhc2",
  offered: "nhc3",
};

const getHomesContractedSum = (cluster) => {
  let result = 0;

  const filterStatus = getFilterStatus();
  for (let i = 0; i < filterStatus.length; i++) {
    result += cluster[statusKeyTranslation[filterStatus[i]]];
  }

  return result;
};

const getFilterStatus = () => {
  const filterHelperService = new FilterHelperService();
  return filterHelperService.getFilter()[
    filterHelperService.getSingleSelectedStatusKey()
  ];
};

export default ClusterMarkerHomesContractedService;
