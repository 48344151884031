<template>
  <div>
    <v-row v-if="polygons.length === 0" no-gutters>
      <v-col>
        <v-btn
          v-if="drawingNewPolygon"
          @click="abortDrawingNewPolygon"
          block
          color="error"
        >
          <v-icon class="mr-2">mdi-close</v-icon>
          Zeichnen Abbrechen
        </v-btn>
        <v-btn v-else-if="polygons.length === 0" block @click="drawNewPolygon">
          <v-icon class="mr-2">mdi-draw</v-icon>
          Polygon zeichnen
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-show="drawingNewPolygon">
      <v-col>
        <v-alert text icon="mdi-information-outline">
          Bitte klicken Sie in die Karte um ein neues Polygon zu zeichnen.
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="polygons[0]" no-gutters>
      <v-col>
        <v-btn block class="mb-5" @click.prevent="removePolygon(polygons[0])">
          <v-icon class="mr-2">mdi-close</v-icon>
          Auswahl entfernen
        </v-btn>

        <span class="title">Informationen zur aktuellen Auswahl</span><br />
        <span>{{ polygonArea }} km² Fläche</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AddressFilterService from "@/services/addressfilter.service";
import { mapGetters } from "vuex";
import TurfHelperService from "@/services/turf-helper.service";

export default {
  name: "ToolSettingsPolygon",
  data() {
    return {
      activePolygonIndex: null,
      loadingNewPolygon: false,
      drawingNewPolygon: false,
    };
  },
  computed: {
    addressIds() {
      let addressIds = [];

      for (let i = 0, l = this.polygons.length; i < l; i++) {
        const addresses = this.polygons[i].addresses;

        for (const address of addresses) {
          if (!addressIds.includes(address.address.id)) {
            addressIds.push(address.address.id);
          }
        }
      }

      return addressIds;
    },
    polygonArea() {
      const turfHelperService = new TurfHelperService();
      let route = [];

      this.polygons[0].polygon.getPaths().forEach((p) => {
        p.getArray().forEach((pt) => {
          route.push([pt.lat(), pt.lng()]);
        });
      });

      return Math.round(turfHelperService.getPolygonArea(route) / 10000) / 100;
    },
    ...mapGetters(["polygons"]),
  },
  methods: {
    abortDrawingNewPolygon() {
      this.drawingNewPolygon = false;
      this.$root.$emit("abortDrawingNewPolygon");
    },

    drawNewPolygon() {
      this.drawingNewPolygon = true;
      this.$root.$emit("drawNewPolygon");
    },

    removeAllPolygons() {
      for (let i = 0, l = this.polygons.length; i < l; i++) {
        this.polygons[i].polygon.setMap(null);
        this.$store.dispatch("deletePolygon", this.polygons[i]);
      }

      this.$store.dispatch("setMapToolFilter", null);
    },

    removePolygon(polygon) {
      polygon.polygon.setMap(null);
      this.$store.dispatch("deletePolygon", polygon);
    },

    getPolygonInfos(polygon) {
      const addresses = polygon.addresses;
      let countAfterFilter = 0;
      let streetNames = {};
      let zipCodes = [];

      for (const address of addresses) {
        const streetName = address.address.street;
        const zipCode = address.address.zipCode;

        if (typeof streetNames[streetName] === "undefined") {
          streetNames[streetName] = 0;
        }

        if (!zipCodes.includes(zipCode)) {
          zipCodes.push(zipCode);
        }

        streetNames[streetName] = streetNames[streetName] + 1;

        if (AddressFilterService.filterAddress(address)) {
          countAfterFilter++;
        }
      }

      return {
        countAfterFilter,
        streetNames,
        zipCodes,
      };
    },
  },
  watch: {
    addressIds() {
      if (this.polygons.length === 0) {
        this.$store.dispatch("setMapToolFilter", null);
      } else {
        this.$store.dispatch("setMapToolFilter", this.addressIds);
      }

      this.$emit("change", this.polygons.length > 0);
    },
    polygons() {
      this.abortDrawingNewPolygon();
    },
  },
  created() {
    this.$root.$on("addedPolygon", this.addPolygon);
  },
  beforeDestroy() {
    this.removeAllPolygons();
  },
};
</script>

<style scoped></style>
