const createHTMLMapMarker = ({
  OverlayView = window.google.maps.OverlayView,
  ...args
}) => {
  class HTMLMapMarker extends OverlayView {
    constructor() {
      super();
      this.position = args.position;
      this.html = args.html;
      this.setMap(args.map);
    }

    createDiv() {
      this.div = document.createElement("div");
      this.div.style.position = "absolute";
      if (this.html) {
        this.div.innerHTML = this.html;
      }
      window.google.maps.event.addDomListener(this.div, "click", () => {
        window.google.maps.event.trigger(this, "click");
      });
    }

    appendDivToOverlay() {
      const panes = this.getPanes();

      panes.overlayMouseTarget.appendChild(this.div);
    }

    positionDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.position);

      if (point) {
        this.div.style.left = point.x - this.div.clientWidth / 2 + "px";
        this.div.style.top = point.y - this.div.clientHeight / 2 + "px";
      }
    }

    draw() {
      if (!this.div) {
        this.createDiv();
        this.appendDivToOverlay();
      }
      this.positionDiv();
    }

    remove() {
      if (this.div) {
        this.div.remove();
      }
    }

    getPosition() {
      return this.position;
    }

    getDraggable() {
      return false;
    }
  }

  return new HTMLMapMarker();
};

export default createHTMLMapMarker;
