<template>
  <v-avatar
    :left="left"
    class="mr-3"
    :color="bgColor + ' lighten-5'"
    :size="size"
  >
    <v-icon v-if="notAssigned">mdi-account-remove-outline</v-icon>
    <span v-else>{{ salesPersonInitials }}</span>
  </v-avatar>
</template>

<script>
export default {
  name: "SalesPersonAvatar",
  props: {
    left: {
      type: Boolean,
      default: false,
    },
    salesPerson: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
    size: {
      type: Number,
      required: false,
    },
  },
  computed: {
    salesPersonInitials() {
      return (
        this.salesPerson.firstName.substring(0, 1) +
        this.salesPerson.lastName.substring(0, 1)
      ).toUpperCase();
    },
    bgColor() {
      return this.color ?? "";
    },
    notAssigned() {
      return this.salesPerson.id === 0;
    },
  },
};
</script>

<style scoped></style>
