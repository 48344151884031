<template>
  <div>
    <v-btn color="info" class="mb-3" outlined @click="saveFilter">
      <v-icon class="mr-2">mdi-filter-outline</v-icon>
      Filter Anwenden
    </v-btn>
    <v-btn color="info" class="mb-3 ml-3" outlined @click="centerToHomeView">
      <v-icon class="mr-2">mdi-home-map-marker</v-icon>
      Home
    </v-btn>

    <div class="row">
      <div
        v-for="statusGroup in status"
        :key="statusGroup.id"
        class="col-md-12 col-sm col-12 mb-0"
      >
        <status-select
          :status-group="statusGroup"
          multiple
          v-model="statusFilter[statusGroup.name]"
          @change="emitChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatusSelect from "../../../components/StatusSelect";

export default {
  name: "MapFilter",
  components: {
    StatusSelect,
  },
  data() {
    return {
      currentMapView: "marker",
      activeStatusArray: [],
      selectedStatusGroup: null,
      item: null,
      statusFilter: {},
      salesPersonsFilter: [],
      dwellingUnitsFilter: {
        rangeLimits: [1, 85],
        range: [1, 85],
      },
    };
  },

  computed: {
    ...mapGetters(["status"]),
    loadedAddressesCount() {
      return this.$store.getters.loadedAddressesCount;
    },
    statusGroups() {
      return this.$store.getters.statusGroups;
    },
    statusFilterActive() {
      for (const statusGroup of Object.entries(this.statusFilter)) {
        if (statusGroup[0] === "product") {
          if (statusGroup[1]) {
            return true;
          }
        } else {
          if (statusGroup[1].length > 0) {
            return true;
          }
        }
      }

      return false;
    },
    dwellingUnitsFilterActive() {
      return (
        this.dwellingUnitsFilter.range[0] >
          this.dwellingUnitsFilter.rangeLimits[0] ||
        this.dwellingUnitsFilter.range[1] <
          this.dwellingUnitsFilter.rangeLimits[1]
      );
    },
    filterActive() {
      return (
        this.statusFilterActive ||
        this.salesPersonsFilter.length > 0 ||
        this.dwellingUnitsFilterActive
      );
    },
  },

  methods: {
    changeMapView(value) {
      this.$emit("changeMapView", value);
    },

    refreshMap() {
      this.$emit("refreshMap", true);
    },

    centerToHomeView() {
      this.$emit("centerToHomeView", true);
    },

    saveFilter() {
      for (let i = 0; i < this.status.length; i++) {
        if (!this.statusFilter[this.status[i].name]) {
          this.statusFilter[this.status[i].name] = [];
        }
      }

      this.$store.dispatch(
        "setStatusFilter",
        JSON.parse(JSON.stringify(this.statusFilter))
      );

      this.refreshMap();
    },

    emptyFilter() {
      this.$store.dispatch("setStatusFilter", null);

      this.refreshMap();
    },

    emitChange() {
      this.$emit("change", this.filterActive);
    },
  },
};
</script>

<style scoped></style>
