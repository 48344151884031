const CLUSTER_TYPES = ["county", "cluster", "city", "cityDistrict", "street"];

class AddressTransformerService {
  static transformAddressData(input) {
    let output = [];

    input.map((node) => {
      if (CLUSTER_TYPES.includes(node["@type"])) {
        if (
          node.items.length > 0 &&
          (CLUSTER_TYPES.includes(node.items[node.items.length - 1]["@type"]) ||
            node.items[0]["@type"] === "address")
        ) {
          output = output.concat(node.items.filter((obj) => !!obj["@type"]));
        } else {
          output.push(node);
        }
      } else if (node["@type"] === "address") {
        output.push(node);
      }
    });

    return output;
  }
}

export default AddressTransformerService;
